import { useEffect, useRef } from "react"
import { GlobeMethods } from "react-globe.gl"
import ReactGlobe from "react-globe.gl"

type Coordinates = {
  lat: number
  lng: number
}

const cities: { [key: string]: Coordinates } = {
  "new-york": { lat: 40.7128, lng: -74.006 },
  frankfurt: { lat: 50.1109, lng: 8.6821 },
  "los-angeles": { lat: 34.052235, lng: -118.243683 },
  "san-francisco": { lat: 37.773972, lng: -122.431297 },
  london: { lat: 51.509865, lng: -0.118092 },
  "buenos-aires": { lat: -34.603722, lng: -58.381592 },
  sydney: { lat: -33.865143, lng: 151.2099 },
  brazilia: { lat: -15.793889, lng: -47.882778 },
  "cape-town": { lat: -33.918861, lng: 18.4233 },
  florida: { lat: 27.994402, lng: -81.760254 },
  "addis-ababa": { lat: 9.005401, lng: 38.763611 },
  cairo: { lat: 30.033333, lng: 31.233334 },
  lago: { lat: 6.465422, lng: 3.406448 },
  mumbai: { lat: 19.07609, lng: 72.877426 },
  rio: { lat: -22.908333, lng: -43.196388 },
  "sao-paulo": { lat: -23.533773, lng: -46.62529 },
  medellin: { lat: 6.230833, lng: -75.590553 },
  paris: { lat: 48.864716, lng: 2.349014 },
}

function getCombinations(arr: Array<Coordinates>) {
  const result = []
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      result.push([arr[i], arr[j]])
    }
  }
  return result
}

const arcsData = getCombinations(Object.values(cities))
  .filter(() => Math.random() >= 0.5)
  .map(([start, end]) => ({
    startLat: start.lat,
    startLng: start.lng,
    endLat: end.lat,
    endLng: end.lng,
    color: ["#06b6d4", "#06b6d4", "#06b6d4"],
  }))

function Globe({
  width = 1360,
  height = 1200,
  ...props
}: {
  width?: number
  height?: number
}) {
  const globeEl = useRef<GlobeMethods>()

  useEffect(() => {
    if (!globeEl.current) {
      return
    }

    globeEl.current.controls().enablePan = false
    globeEl.current.controls().enableRotate = false
    globeEl.current.controls().enableZoom = false
    globeEl.current.controls().autoRotate = true
    globeEl.current.controls().autoRotateSpeed = -1
    globeEl.current.pointOfView({ altitude: 1.55 })
    globeEl.current.camera().updateProjectionMatrix()
  }, [globeEl.current])

  return (
    <div {...props}>
      <ReactGlobe
        ref={globeEl}
        width={width}
        height={height}
        arcsData={arcsData}
        arcColor={"color"}
        showAtmosphere
        rendererConfig={{
          antialias: false,
          failIfMajorPerformanceCaveat: true,
          powerPreference: "high-performance",
          debug: { checkShaderErrors: false },
        }}
        animateIn={true}
        backgroundColor="rgb(248, 250, 252)"
        atmosphereColor="#3730A3"
        atmosphereAltitude={0.05}
        globeImageUrl="/images/ory_world_dark.png"
        arcDashLength={0.1}
        arcDashGap={1}
        arcDashAnimateTime={5000}
      />
    </div>
  )
}

export default Globe
